import React from 'react';

import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo/lib/jsonld/local-business';

import tw from 'twin.macro';

import { useSeo, useContact } from '@queries';
import { stripHtml } from '@helpers';
import { Layout } from '@layouts';
import {
    Section,
    Row,
    Column,
    Subheading,
    Small,
    HoursTable,
    Map,
} from '@components';

const ContactPage = () => {
    const { description_contact } = useSeo();

    const {
        title,
        name,
        institution,
        idEpu,
        bankingAccount,
        phoneNumber,
        email,
        workingHours,
        acceptingHours,
        address_street,
        address_city,
    } = useContact();

    const siteTitle = 'Kontakt';

    return (
        <>
            <LocalBusinessJsonLd
                type="LegalService"
                id="https://komornik-olsztyn.pl"
                name={`${stripHtml(title)} ${name}, ${institution}`}
                description={description_contact}
                url="https://komornik-olsztyn.pl/"
                telephone="+48895275489"
                address={{
                    streetAddress: address_street,
                    addressLocality: 'Olsztyn',
                    postalCode: '10-038',
                    addressRegion: 'warmińsko-mazurskie',
                    addressCountry: 'PL',
                }}
                geo={{
                    latitude: '53.77775',
                    longitude: '20.47889',
                }}
                overrides={{
                    openingHours: ['Mo 8:00-16:00', 'Tu-Fr 8:00-15:00'],
                }}
            />
            <Layout title={siteTitle} description={description_contact}>
                <Section main heading={siteTitle} spaceTop>
                    <div css={tw`mb-8 sm:mb-12`}>
                        <Subheading
                            css={tw`to-sm:text-lg to-md:text-2xl text-3xl mt-1 lg:mt-2 mb-1`}
                            dangerouslySetInnerHTML={{
                                __html: stripHtml(title),
                            }}
                        />
                        <Subheading>
                            {name}
                            <br />
                            {institution}
                        </Subheading>
                        <Subheading>
                            ID EPU: {idEpu}
                        </Subheading>
                    </div>
                    <div css={tw`mb-8 sm:mb-12`}>
                        <Small>Rachunek Bankowy Kancelarii:</Small>
                        <Subheading>
                            {bankingAccount}
                        </Subheading>
                    </div>
                    <Row>
                        <Column>
                            <Small>Numer Telefonu:</Small>
                            <Subheading>
                                <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                            </Subheading>
                        </Column>
                        <Column>
                            <Small>Adres E-mail:</Small>
                            <Subheading>
                                <a href={`mailto:${email}`}>{email}</a>
                            </Subheading>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <Small>Godziny Pracy Kancelarii:</Small>
                            <HoursTable data={workingHours} />
                        </Column>
                        <Column>
                            <Small>Godziny Przyjęć Interesantów Przez Komornika:</Small>
                            <HoursTable data={acceptingHours} />
                        </Column>
                    </Row>
                    <div css={tw`mt-8 sm:mt-12`}>
                        <Small>Adres Kancelarii:</Small>
                        <Subheading>
                            {address_street}
                            <br />
                            {address_city}
                        </Subheading>
                        <Map />
                    </div>
                </Section>
            </Layout>
        </>
    );
};

export default ContactPage;
